import CartService from "../../services/cartService"

export const state = () => ({
  cart: null,
  clientUUid: null,
  selectedSalon: null,
  preselectedEmployee: null,
  unavailableProducts: [],
  salonPoolDefaultSiteId: 0,
  showCrossSiteDialog: false,
  previousSelectedSalon: 0
})
const getters = {
  getCart: state => state.cart,
  getSelectedSalon: state => state.selectedSalon,
  getPreselectedEmployee: state => state.preselectedEmployee,
  getShowCrossSiteDialog: state => state.showCrossSiteDialog,
  getPreviousSelectedSalon: state => state.previousSelectedSalon,
  getUnavailableProducts: state => state.unavailableProducts,
  getSalonPoolDefaultSiteId: state => state.salonPoolDefaultSiteId,
  getCartCount: state => {
    let total = 0;
    if (state.cart && (
      (state.cart.productList && state.cart.productList.length) ||
      (state.cart.bundlesList && state.cart.bundlesList.length) ||
      (state.cart.treatmentIds && state.cart.treatmentIds.length)
    )) {
      const bundlesCount = state.cart.bundlesList.map((x) => x.quantity).reduce((amount, quantity) => amount + quantity, 0);
      const productsCount = state.cart.productList.map((x) => x.quantity).reduce((amount, quantity) => amount + quantity, 0);
      total = bundlesCount + productsCount + state.cart.treatmentIds.length
    }
    return total
  },
  treatmentIsInCart: state => id => state.cart && state.cart.treatmentIds && state.cart.treatmentIds.includes(id),
  productIsInCart: state => id => state.cart && state.cart.productList && state.cart.productList.length && state.cart.productList.map(x => x.id).includes(id),
  bundleIsInCart: state => id => state.cart && state.cart.bundlesList && state.cart.productList.length && state.cart.productList.map(x => x.campaignId).includes(id),
  getCartTreatments: state => state.cart && state.cart.treatments ? state.cart.treatments : [],
  getCartBundles: state => state.cart && state.cart.bundlesList ? state.cart.bundlesList : [],
  getCartProducts: state => state.cart && state.cart.productList ? state.cart.productList : [],

  getTotalTreatmentsPrice: state => {
    let treatmentsPrice = 0;
    if (state.cart && state.cart.treatments) {
      state.cart.treatments.length
        ? state.cart.treatments.forEach((x) => {
          treatmentsPrice += Math.min(...x.pricePerSite.map(p => p.price)) || 0;
        })
        : 0;
    }
    return treatmentsPrice
  },
  getCartProductAndBundleIds: (state, getters) => {
    let ids = [];
    if (getters.getCartBundles.length > 0) {
      getters.getCartBundles.forEach((b) => {
        b.productList.forEach((p) => {
          ids.push(p.base64Id);
        });
      });
    }
    if (getters.getCartProducts.length > 0) {
      ids.push(...getters.getCartProducts.map((x) => x.base64Id));
    }
    return ids;
  },

  getTotalProductsPrice: state => {
    let productsPrice = 0;
    if (state.cart.productList) {
      state.cart.productList.length
        ? state.cart.productList.forEach((x) => {
          itemDiscount(x).discounted
            ? (productsPrice += itemDiscount(x).totalSalePrice)
            : (productsPrice += x.price * x.quantity);
        })
        : 0;
    }
    if (state.cart.bundlesList) {
      state.cart.bundlesList.length
        ? state.cart.bundlesList.forEach((x) => {
          productsPrice += x.campaignValue * x.quantity;
        })
        : 0;
    }
    return productsPrice
  },

  getTotalProductsDiscounts: state => {
    let totalProductsDiscount = 0
    const discountAmounts = [];
    if (state.cart.productList) {
      state.cart.productList.map((x) => {
        const discount = itemDiscount(x);
        if (discount.discounted) {
          discountAmounts.push(discount.totalValue - discount.totalSalePrice);
        } else {
          return 0;
        }
      });
    }
    if (state.cart.bundlesList) {
      for (const bundle of state.cart.bundlesList) {
        discountAmounts.push(
          bundle.totalValue * bundle.quantity -
          bundle.campaignValue * bundle.quantity
        );
      }
    }
    if (discountAmounts.length) {
      totalProductsDiscount = discountAmounts.reduce(
        (total, x) => total + x
      );
    } else {
      totalProductsDiscount = 0;
    }
    return totalProductsDiscount
  },

  // calculatePrices: state => {
  //   calculateTotalProductsDiscounts();
  //   calculateTotalProductsPrice();
  //   calculateTotalTreatmentsPrice();
  //   productsTotalWithoutDiscounts.value =
  //     totalProductsPrice.value + totalProductsDiscount.value;
  // }
}

const mutations = {
  setCart(state, value) {
    state.cart = value
  },
  setClientUUid(state, value) {
    state.clientUUId = value
  },
  setPreviousSelectedSalon(state, value) {
    if (value) {
      state.previousSelectedSalon = value;
    } else {
      state.previousSelectedSalon = state.selectedSalon;
    }
  },
  setShowCrossSiteDialog(state, value) {
    state.showCrossSiteDialog = value;
    state.previousSelectedSalon = 0;
  },
  setSelectedSalon(state, value) {
    if (value && value !== 0 && state.cart && state.cart.siteId && state.cart.siteId !== value) {
      state.showCrossSiteDialog = true;
    } else if (value && value !== null) {
      state.selectedSalon = value
      setToLocalStorage('selectedSalon', value)
      removeFromLocalStorage('preselectedEmployee')
    } else {
      removeFromLocalStorage('selectedSalon')
      removeFromLocalStorage('preselectedEmployee')
      state.selectedSalon = null;
    }
  },
  setUnavailableProducts(state, value) {
    state.unavailableProducts = value
  },
  setSalonPoolDefaultSiteId(state, value) {
    state.salonPoolDefaultSiteId = value
  },
  setPreselectedEmployee(state, value) {
    if (value) {
      state.preselectedEmployee = value
      setToLocalStorage('preselectedEmployee', mapToBookingPreselectedEmployee(value) || null)
    } else {
      state.preselectedEmployee = null
      removeFromLocalStorage('preselectedEmployee')
    }
  }
}
const actions = {
  async getCart({ commit, state, rootGetters }) {
    const cartService = new CartService({ axios: this.$axios, env: this.$config })
    const chainId = rootGetters["cmsConfigHomepage/getChainId"];
    if (chainId !== null) {
      let result = await cartService.GetCart(chainId, state.clientUUId, state.preselectedEmployee?.base64EmployeeId || '')
      if (result && result.clientUUId) {
        let selectedSalon = getFromLocalStorage('selectedSalon') || null
        commit('setClientUUid', result.clientUUId || null)
        commit('setSelectedSalon', selectedSalon)
        setToLocalStorage('clientUUid', result.clientUUId || null)
        commit('setCart', result)
      }
      return true
    }
    return false
  },
  async insertOrUpdateProduct({ commit, state }, product) {
    const cartService = new CartService({ axios: this.$axios, env: this.$config })
    let result = await cartService.InsertOrUpdateProduct(product, state.clientUUId, state.preselectedEmployee?.base64EmployeeId || '')
    if (result && result.clientUUId) {
      commit('setClientUUid', result.clientUUId || null)
      setToLocalStorage('clientUUid', result.clientUUId || null)
      commit('setCart', result)
    }
  },
  async insertOrUpdateTreatment({ commit, state }, treatment) {
    const cartService = new CartService({ axios: this.$axios, env: this.$config })
    let siteId = getFromLocalStorage('selectedSalon')
    let result = await cartService.InsertOrUpdateTreatment(treatment, siteId, state.clientUUId, state.preselectedEmployee?.base64EmployeeId || '')
    if (result && result.clientUUId) {
      commit('setClientUUid', result.clientUUId || null)
      setToLocalStorage('clientUUid', result.clientUUId || null)
      commit('setCart', result)
    }
  },
  async deleteShoppingCartItem({ commit, state, rootGetters }, item) {
    const cartService = new CartService({ axios: this.$axios, env: this.$config })
    const chainId = rootGetters["cmsConfigHomepage/getChainId"];
    let siteId = getFromLocalStorage('selectedSalon')
    if (chainId !== null) {
      let result = await cartService.DeleteShoppingCartItem(item.item, chainId, state.clientUUId, item.type, state.preselectedEmployee?.base64EmployeeId || '', siteId)
      if (result && result.clientUUId) {
        commit('setClientUUid', result.clientUUId || null)
        setToLocalStorage('clientUUid', result.clientUUId || null)
        commit('setCart', result)
      }
      if ((result && result.treatmentIds.length <= 0) || (result && !result.treatmentIds) || !result) {
        commit('setClientUUid', null)
        removeFromLocalStorage('clientUUid')
      }
      commit('setCart', result)
    }
  },
  async removeCartTreatments({ commit, state, dispatch }, req) {
    const cartService = new CartService({ axios: this.$axios, env: this.$config })

    if (req.siteId !== null) {
      let result = await cartService.RemoveCartTreatments(req)
      if (result) {
        let siteId = req.nextSiteId && req.nextSiteId !== 0 ? req.nextSiteId : null
        commit('setCart', [])
        commit('setSelectedSalon', siteId)
        commit('setClientUUid', null)
      }
    }
  },
}

function setToLocalStorage(key, value) {
  if (window) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
function getFromLocalStorage(key) {
  if (window) {
    const item = localStorage.getItem(key);
    if (item !== undefined && item !== null && item !== 'undefined') {
      return JSON.parse(item);
    }
  }
}
function removeFromLocalStorage(key) {
  if (window) {
    localStorage.removeItem(key);
  }
}
function mapToBookingPreselectedEmployee(employee) {
  return {
    availableForOnlineBooking: employee.availableForOnlineBooking,
    description: employee.description,
    employeeId: employee.employeeId,
    employeeIdBase64: employee.base64EmployeeId,
    firstName: employee.firstName,
    fixitSiteId: "",
    imageUrl: employee.imgUrl,
    imageUrlLarge: employee.imageUrlLarge,
    imageUrlMedium: employee.imageUrlMedium,
    imageUrlRounded: employee.imageUrlRounded,
    imageUrlSmall: employee.imageUrlSmall,
    imageVersion: employee.imageVersion,
    lastName: "",
    nameUrlSafe: employee.urlSafeName,
    siteNameUrlSafe: "",
    siteId: employee.siteId,
    nameUrlSafe: "",
    sortNo: employee.sortNo,
    title: employee.title,
    treatments: employee.treatments,
    visibleOnDigitalChannels: employee.visibleOnDigitalChannels,
    webId: employee.webId
  }
}

function itemDiscount(cartProduct) {
  let salePrice = cartProduct.price;
  let totalPriceForQuantity = 0;
  let discountedQuantity = 0;
  let discountText = '';
  if (cartProduct.discounts.length) {
    cartProduct.discounts.forEach((discount) => {
      totalPriceForQuantity += discount.discountPrice * discount.quantity;
      discountedQuantity += discount.quantity;
      discountText = discount.discountText;
    });
  }
  if (discountedQuantity <= cartProduct.quantity && discountedQuantity > 0) {
    totalPriceForQuantity +=
      cartProduct.price * (cartProduct.quantity - discountedQuantity);
    salePrice =
      totalPriceForQuantity / (cartProduct.quantity + discountedQuantity);
  }
  return {
    salePrice: salePrice,
    price: cartProduct.price,
    totalSalePrice:
      totalPriceForQuantity || cartProduct.price * cartProduct.quantity,
    totalValue: cartProduct.price * cartProduct.quantity,
    discounted: discountedQuantity > 0,
    discountText: discountText,
  };
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
