// Disabling console logs once app is built for deployment
export default ({ app }) => {
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
     // Override console methods to do nothing
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
      console.info = () => {};
      console.debug = () => {};
  }
};
